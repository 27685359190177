import React from "react";
import styles from "./styles.module.scss";
import Logo from "../../../components/Logo";
import PillButton, { PillStyle } from "../../../components/buttons/PillButton";
import Icon, { IconType } from "../../../components/Icon";
import Footer from "../../../components/Footer";
import GameBasePage from "../GameBasePage";
import { NavLink } from "react-router-dom";
import GameManager from "../../../gameData/GameManager";
import { GameMode } from "../../../models";

const buttonActionIconStyle = {
  width: "7.41vh",
  height: "7.41vh",
  color: "white",
};

function HomePage() {
  const partyModeOnClick = () => {
    GameManager.getInstance().setMode(GameMode.Party);
  };

  const impostorModeOnClick = () => {
    GameManager.getInstance().setMode(GameMode.Impostor);
  };

  return (
    <GameBasePage>
      <div className={styles.logoContainer}>
        <Logo className={styles.logo} />
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.buttonContainer}>
          <PillButton
            onClick={partyModeOnClick}
            pillStyle={PillStyle.PINK_OUTLINE}
            style={{width: "50vh"}}
            icon={
              <Icon iconType={IconType.TROPHY} style={buttonActionIconStyle} />
            }
            text="Party mode"
          />
          <PillButton
            onClick={impostorModeOnClick}
            pillStyle={PillStyle.PINK_OUTLINE}
            style={{
              width: "50vh",
              backgroundImage: `url(https://palta-client.framad.people.amazon.dev/images/rainbow-gradient.jpg)`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            icon={
              <Icon iconType={IconType.SEARCH} style={buttonActionIconStyle} />
            }
            text="Find the impostor"
          />
        </div>
        <Footer />
      </div>
    </GameBasePage >
  );
}

export default HomePage;
