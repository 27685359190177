import React from "react";
import QRCode from "react-qr-code";

interface QRProps {
  qrValue: string;
  size?: string;
}

function QR({ qrValue, size }: QRProps) {
  const qrSize = size ?? "20.65vh";
  return (
    <QRCode
      value={qrValue}
      level="M"
      aria-label="qr_code"
      style={{ height: qrSize, maxWidth: qrSize, width: qrSize }}
    />
  );
}

export interface QRWrapperProps {
  qrValue: string;
  size?: string;
}

function QRCodeWrapper({ qrValue, size }: QRWrapperProps) {
  if (process.env.ENABLE_QR_LINK) {
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <a target="_blank" href={qrValue} rel="noreferrer">
        <QR qrValue={qrValue} size={size} />
      </a>
    );
  }
  return <QR qrValue={qrValue} size={size} />;
}

export default QRCodeWrapper;
