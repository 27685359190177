import { Socket, io } from "socket.io-client";
import { v4 as uuidV4 } from "uuid";
import { GameData, GameMode, GameState, IMPOSTOR_ID, JoinResponse, Player, PlayingState, Vote } from "../models";
import { SERVER_URL } from "../environment";

class GameManager {
  /* eslint-disable no-use-before-define */
  private static instance: GameManager | null = null;

  private playerId: string;

  private _onStateChanged: ((data: GameData) => void) | null = null;

  socket: Socket | null | undefined;

  gameData: GameData | undefined;


  onStateChanged(callback: (data: GameData) => void) {
    this._onStateChanged = callback;
  }

  private constructor() {
    this.connect();
    // this.onStateChanged = (_) => {};
    this.playerId = uuidV4();
  }

  stateChangedHandler = (data: GameData) => {
    console.log("State Changed:", data);
    if (data.currentState === GameState.Drawing) {
      // handle time left and timeleftmessage
    }
    this.gameData = data;
    if (this._onStateChanged) {
      this._onStateChanged(data);
    }
  };

  private connect() {
    console.log("connecting");
    if (this.isConnected()) {
      console.log("is already connected");
      return;
    }
    this.socket = io(SERVER_URL);
    this.socket.on("stateChanged", this.stateChangedHandler);
  }

  disconnect() {
    this.leave();
  }

  private isConnected(): boolean {
    return this.socket?.connected || false;
  }

  static getInstance(): GameManager {
    if (!GameManager.instance) {
      GameManager.instance = new GameManager();
    }
    return GameManager.instance;
  }

  // Server
  cleanServer() {
    this.socket?.emit("clean");
  }

  setMode(gameMode: GameMode) {
    this.socket?.emit("setMode", gameMode);
  }
  startGame() {
    this.socket?.emit("start");
  }

  restartGame() {
    this.socket?.emit("restart");
  }

  // Player
  userIsLogged(): boolean {
    return (
      this.gameData?.players.find((p) => p.id === this.playerId) !==
      undefined || false
    );
  }

  userDrew() {
    const player = this.gameData?.players.find((p) => p.id === this.playerId);
    if (!player) return false;
    return player.drawSent;
  }

  userVoted() {
    const player = this.gameData?.players.find((p) => p.id === this.playerId);
    if (!player) return false;
    return player.vote !== undefined;
  }

  getPlayersWithDrawings(): Player[] {
    return (
      this.gameData?.players.filter(
        (p) =>
          p.drawSent &&
          (p.playingState === PlayingState.Playing || p.id === IMPOSTOR_ID)
      ) || []
    );
  }

  join(name: string, callback: (response: JoinResponse) => void) {
    if (this.socket && name.trim() !== "") {
      const playerData: Player = {
        id: this.playerId,
        name,
        playingState: PlayingState.NotPlaying,
        drawSent: false,
        draw: undefined,
        vote: undefined,
        avatar: "",
        voteCount: 0,
      };
      this.socket.emit("join", playerData, (response: JoinResponse) => {
        callback(response);
      });
    }
  }

  leave() {
    this.socket?.emit("leave", this.playerId);
  }

  submitDrawing(image: string) {
    console.log("submitDrawing for " + this.playerId);
    this.socket?.emit("submitDrawing", { playerId: this.playerId, image: image });
  }

  vote(vote: string) {
    const voteObject: Vote = {
      playerId: this.playerId,
      votedPlayerId: vote,
    };
    this.socket?.emit("vote", voteObject);
  }

  getWinners(): Player[] {
    const players = this.gameData?.players.filter((p) => p.drawSent);
    if (!players) return [];
    const max = Math.max(...players.map((p) => p.voteCount));
    return players.filter((p) => p.voteCount === max);
  }
}

export default GameManager;
