import React, { PropsWithChildren } from "react";
import Background from "../../../components/Background";
import styles from "./styles.module.scss";

function GameBasePage({
  children,
}: PropsWithChildren<unknown>) {
  return (
    <div className={styles.pageContainer}>
      <Background showAnimation={true} />
      {children}
    </div>
  );
}

export default GameBasePage;
