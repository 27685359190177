import React, { useState, useEffect, useContext } from 'react';
import { GameData, GameState, PlayingState } from '../../../models';
import GameManager from '../../../gameData/GameManager';
// import AppRoute from '../../../AppRoute';
// import { GameDataContext } from '../../../gameData/GameDataContext';

const DebugPage = () => {
  // const { setPageInternal } = useContext(GameDataContext);

  const [name, setName] = useState<string>('');
  const [gameData, setGameData] = useState<GameData>();
  const handleUnload = () => {
    GameManager.getInstance().disconnect();
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);

    // Generate ID for player
    GameManager.getInstance().onStateChanged((data: GameData) => {
      console.log("State changed");
      setGameData(data);
    });
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    }
  }, [])

  function renderVoteButton(): React.ReactNode {
    return (
      <div>
        <button
          onClick={() => { GameManager.getInstance().vote("1") }}
        >Vote</button>
      </div>
    );
  }

  const join = (name: string) => {
    GameManager.getInstance().join(name, (_response) => {
      // if (response.status === "ERROR") {
      //   setPageInternal(AppRoute.IMPOSTOR_MAX_PLAYERS, true);
      // }
    })
  }


  function renderLogin(): React.ReactNode {
    if (GameManager.getInstance().userIsLogged()) {
      return (
        <div>{name}</div>
      )
    }
    return (
      <div>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)} />
        <button onClick={() => join(name)}
          disabled={GameManager.getInstance().userIsLogged()}>Join</button>
        <button
          onClick={() => { GameManager.getInstance().leave() }}
          disabled={!GameManager.getInstance().userIsLogged()}
        >Leave</button>
      </div>
    );
  }

  function renderCanvas(): React.ReactNode {
    return (
      <div>
        Now you are drawing!
      </div>
    );
  }

  return (
    <div>
      {renderLogin()}
      {GameManager.getInstance().userIsLogged() && GameManager.getInstance().gameData?.currentState === GameState.Drawing && renderCanvas()}
      {GameManager.getInstance().userIsLogged() && GameManager.getInstance().gameData?.currentState === GameState.Voting && renderVoteButton()}
    </div>
  );
};

export default DebugPage;
