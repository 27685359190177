enum AppRoute {
  // Game
  HOME = "home",
  DRAWING_GAME = "drawing-game",
  RESULTS_GAME = "results-game",
  DRAWINGS_BOARD_GAME = "drawings-board-game",
  WAITING = "waiting",
  // Player
  JOIN = "join",
  IMPOSTOR_MAX_PLAYERS = "impostor-max-players",
  DRAWING_PLAYER = "drawing-player",
  VOTING_PLAYER = "voting-player",
  WAITING_PLAYER = "waiting-player",
  WAITING_DRAWINGS_PLAYER = "waiting-drawings-player",
  WAITING_VOTES_PLAYER = "waiting-votes-player",
  WAITING_NEXT_ROUND_PLAYER = "waiting-next-round-player",

  LOADER = "",
  DEBUG = "debug",
  DEBUGFIRETV = "debugtv",
  NotFound = "not-found"
}

export default AppRoute;
