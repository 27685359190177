import React, { useContext, useEffect, useState } from "react";
import GameBasePage from "../GameBasePage";
import styles from "./styles.module.scss";
import DrawingsBoard from "../../../components/DrawingsBoard";
import GameManager from "../../../gameData/GameManager";
import { GameDataContext } from "../../../gameData/GameDataContext";
import {GameMode, Player} from "../../../models";
import Timer from "../../../components/Timer";

import gameLogo from "../../../assets/images/sketch-n-guess-party-logo.png";

function GameVotingPage() {
  const [players, setPlayers] = useState<Player[]>([]);
  const { gameData } = useContext(GameDataContext);
  const buttonOnClick = () => {
    GameManager.getInstance().restartGame();
  };

  useEffect(() => {
    setPlayers(GameManager.getInstance().getPlayersWithDrawings());
  }, [gameData?.players]);

  const showLogo =
    GameManager.getInstance().getPlayersWithDrawings().length < 5;
  const timerStyle = {
    ...(!showLogo && { left: "10vh", width: "unset" }),
  };

  return (
    <GameBasePage>
      <div className={styles.mainContainer}>
        <div className={styles.contentContainer}>
          {showLogo && (
            <div className={styles.logoImg}>
              <img src={gameLogo} alt="img" />
            </div>
          )}
          <div className={styles.drawingsContainer}>
            <DrawingsBoard title={gameData?.gameMode === GameMode.Party ? "Vote the favourite" : "Find the impostor!"} subtitle={gameData?.currentPrompt?.toUpperCase()} players={players}/>
          </div>
          <div className={styles.timerContainer} style={timerStyle}>
            <Timer/>
          </div>
        </div>
      </div>
    </GameBasePage>
  );
}

export default GameVotingPage;
