import React, { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

interface GlowProps {
  containerClassName: string;
  glowClassName: string;
}

export default function Glow({
  containerClassName,
  glowClassName,
  children,
}: PropsWithChildren<GlowProps>) {
  return (
    <div className={containerClassName} style={{ position: "relative" }}>
      {children}
      {/* position: absolute to overlap
          It will be positioned relative to its parent, which has to be relative */}
      <div
        className={glowClassName}
        style={{ position: "absolute", zIndex: "-1" }}
      >
        <img
          className={styles.glow}
          src="https://m.media-amazon.com/images/G/01/kindle/tahoe/math-toy-grabber/overlays/Radial_Glow.png"
          alt="glow"
        />
      </div>
    </div>
  );
}
