import React, { CSSProperties } from "react";
import styles from "./styles.module.scss";

export enum AvatarType {
  WHITE,
  BLUE,
  LEAVING,
  QUESTION,
}
export interface AvatarProps {
  avatar?: string;
  style?: CSSProperties | undefined;
  type?: AvatarType;
  disabled?: boolean;
  imgStyle?: CSSProperties | undefined;
}

function colorStyle(color?: AvatarType) {
  switch (color) {
    case AvatarType.BLUE:
      return styles.avatarBlueBorder;
    default:
      return styles.avatarBorder;
  }
}

export default function Avatar({
  avatar,
  style,
  type,
  disabled,
  imgStyle,
}: AvatarProps) {
  const compoundStyle = disabled ? { filter: "grayscale(1)" } : {};
  switch (type) {
    default:
      return (
        <div
          className={colorStyle(type)}
          style={{ ...compoundStyle, ...style }}
        >
          <img
            className={styles.avatarImg}
            style={imgStyle}
            src={avatar}
            alt="avatar"
          />
        </div>
      );
  }
}
