import React, {useContext, useEffect, useState} from "react";
import GameBasePage from "../GameBasePage";
import styles from "./styles.module.scss";
import PillButton, { PillStyle } from "../../../components/buttons/PillButton";
import DrawingsBoard from "../../../components/DrawingsBoard";
import GameManager from "../../../gameData/GameManager";
import Glow from "../../../components/Glow";
import {GameMode, IMPOSTOR_ID, Player, PlayingState, Vote} from "../../../models";
import {GameDataContext} from "../../../gameData/GameDataContext";

import gameLogo from "../../../assets/images/sketch-n-guess-party-logo.png";

function GameResultsPage() {
  const { gameData } = useContext(GameDataContext);

  const buttonOnClick = () => {
    GameManager.getInstance().restartGame();
  };
  const [players, setPlayers] = useState<Player[]>([]);
  const [title, setTitle] = useState<string>("");
  useEffect(() => {
    const winners = GameManager.getInstance().getWinners();
    if (gameData?.gameMode === GameMode.Party) {
      setPlayers(winners);
      setTitle("Best");
    } else {
      setPlayers(GameManager.getInstance().getPlayersWithDrawings());
      const won = winners.filter((winner) => winner.id === IMPOSTOR_ID).length > 0;
      setTitle(won ? "You found the impostor!" : "The impostor tricked you!");
    }
  }, []);

  const showLogo =
    GameManager.getInstance().getPlayersWithDrawings().length < 5;

  return (
    <GameBasePage>
      <div className={styles.mainContainer}>
        <div className={styles.contentContainer}>
          {showLogo && (
            <div className={styles.logoImg}>
              <img src={gameLogo} alt="img" />
            </div>
          )}
          <div className={styles.drawingsContainer}>
            <Glow
              containerClassName={styles.drawingPromptContainer}
              glowClassName={styles.drawingPromptGlow}
            >
              <DrawingsBoard title={title} subtitle={gameData?.currentPrompt?.toUpperCase()} players={players}/>
            </Glow>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <PillButton
            onClick={buttonOnClick}
            pillStyle={PillStyle.PINK_OUTLINE}
            text="NEXT ROUND"
          />
        </div>
      </div>
    </GameBasePage>
  );
}

export default GameResultsPage;
