import React, { useContext } from "react";
import GameBasePage from "../GameBasePage";
import styles from "./styles.module.scss";
import QRCodeBadge from "../../../components/QRCodeBadge";
import PillButton, { PillStyle } from "../../../components/buttons/PillButton";
import PlayersDisplay from "../../../components/PlayersDisplay";
import { GameDataContext } from "../../../gameData/GameDataContext";
import GameManager from "../../../gameData/GameManager";
import Icon, { IconType } from "../../../components/Icon";

import gameLogo from "../../../assets/images/sketch-n-guess-party-logo.png";

const buttonActionIconStyle = {
  width: "4vh",
  height: "4vh",
  color: "white",
};

function GameWaitingPage() {
  const { gameData } = useContext(GameDataContext);
  const qrCodeText = "Scan the QR Code to join!";

  const buttonOnClickReset = () => {
    GameManager.getInstance().cleanServer();
    // TODO: navigate to drawing page.
  };

  const buttonOnClickStart = () => {
    GameManager.getInstance().startGame();
    // TODO: navigate to drawing page.
  };

  return (
    <>
      <div style={{ position: "absolute", top: "3vh", right: "3vh" }}>
        <PillButton
          icon={<Icon iconType={IconType.HOME} style={buttonActionIconStyle} />}
          onClick={buttonOnClickReset}
          pillStyle={PillStyle.TEAL}
          style={{
            paddingLeft: "2.2vh",
          }}
        />
      </div>
      <GameBasePage>
        <div className={styles.mainContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.instructionsContainer}>
              <div className={styles.logoImg}>
                <img src={gameLogo} alt="img" />
              </div>
              <div className={styles.qrCodeContainer}>
                <div className={styles.qrCodeText}>{qrCodeText}</div>
                <QRCodeBadge />
              </div>
            </div>
            <div className={styles.playersContainer}>
              <PlayersDisplay />
            </div>
          </div>
          <div className={styles.buttonContainer}>

            <PillButton
              disabled={(gameData?.players.length || 0) < 2}
              onClick={buttonOnClickStart}
              pillStyle={PillStyle.PINK_OUTLINE}
              text="START"
            />
          </div>
        </div>
      </GameBasePage>
    </>
  );
}

export default GameWaitingPage;
