import React from "react";
import Icon, { IconType } from "../Icon";
import styles from "./styles.module.scss";

function Footer() {
  const footerText =
    "Amazon Play Together games can be played between kids and grownups in the same Amazon household. Grownups can join from Parent Dashboard on Amazon.com, or by scanning the code. Kids can play by finding the game on their Fire Tablet or the Amazon Kids+ mobile app.";
  return (
    <div className={styles.footerContainer}>
      <Icon
        iconType={IconType.PARENT}
        style={{ width: "12vh", height: "12vh" }}
      />
      <p className={styles.footerText}>{footerText}</p>
    </div>
  );
}

export default Footer;
