import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { GameDataProvider } from "./gameData/GameDataContext";

function RootPage(): JSX.Element {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <GameDataProvider>
          <App />
        </GameDataProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default RootPage;
