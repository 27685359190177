import React, { useContext } from "react";
import Board, { BoardType } from "../Board";
import { GameDataContext } from "../../gameData/GameDataContext";
import { IMPOSTOR_ID, Player } from "../../models";

export default function PlayersDisplay() {
  const {gameData} = useContext(GameDataContext);

  return <Board type={BoardType.PLAYERS} players={gameData?.players.filter(p => p.id !== IMPOSTOR_ID)} />;
}
