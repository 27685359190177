import React, { useState, useEffect, useContext } from "react";
import { GameState, PlayingState } from "../../../models";
import GameManager from "../../../gameData/GameManager";
import { GameDataContext } from "../../../gameData/GameDataContext";

function DebugTvPage() {
  const { gameData } = useContext(GameDataContext);
  const [remainingSeconds, setRemainingSeconds] = useState<number>(0);

  function renderStatus(): React.ReactNode {
    return (
      <div>
        <h1>Status</h1>
        <div>
          <button
            type="button"
            onClick={() => {
              GameManager.getInstance().cleanServer();
            }}
          >
            Reset Server
          </button>
          Current state:
          {gameData?.currentState}
          <hr />
        </div>
      </div>
    );
  }

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timer | undefined;

    if (
      GameManager.getInstance().gameData?.currentState === GameState.Drawing
    ) {
      setRemainingSeconds(30);
      intervalId = setInterval(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [GameManager.getInstance().gameData?.currentState]);

  function renderFireTv(): React.ReactNode {
    const header = <h1>FireTV</h1>;
    const components = [header];
    components.push(
      <>
        <div>
          Currently playing:
          {gameData?.players
            .filter((p) => p.playingState === PlayingState.Playing)
            .map((p) => p.name)
            .join(" | ")}
        </div>
        <div>
          Players in queue to play:
          {gameData?.players
            .filter((p) => p.playingState === PlayingState.NotPlaying)
            .map((p) => p.name)
            .join(" | ")}
        </div>
        <div>
          Players who drew:
          {gameData?.players
            .filter(
              (p) =>
                p.playingState === PlayingState.Playing && p.drawSent === true
            )
            .map((p) => `${p.name}`)
            .join(" | ")}
        </div>
        <div>
          Players who voted:{" "}
          {gameData?.players
            .filter(
              (p) =>
                p.playingState === PlayingState.Playing && p.vote !== undefined
            )
            .map((p) => `${p.name} (${p.vote?.votedPlayerId})`)
            .join(" | ")}
        </div>
      </>
    );
    if (
      GameManager.getInstance().gameData?.currentState === GameState.Drawing
    ) {
      components.push(<div>HURRY UP!!! {remainingSeconds}</div>);
    }

    return components;
  }

  return (
    <>
      {renderStatus()}
      <hr />
      {renderFireTv()}
    </>
  );
}

export default DebugTvPage;
