import React, { useContext } from "react";
import styles from "./styles.module.scss";
import PlayerBasePage from "../PlayerBasePage";
import { GameDataContext } from "../../../gameData/GameDataContext";

interface WaitingPlayerPageProps {
  message: string
}

function PlayerWaitingPage({message}: WaitingPlayerPageProps) {
  const { gameData } = useContext(GameDataContext);
  return (
    <PlayerBasePage>
      <div className={styles.mainContainer}>
        <div className={styles.message}>{message}</div>
      </div>
    </PlayerBasePage>
  );
}

export default PlayerWaitingPage;
