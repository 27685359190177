import React from "react";
import styles from "./styles.module.scss";

interface BackgroundProps {
  showAnimation?: boolean;
}

export default function Background({ showAnimation = true }: BackgroundProps) {
  return (
    <div className={styles.background}>
      {showAnimation && (
        <>
          <iframe
            title="background-1"
            src="https://giphy.com/embed/h7uTwqEHysbd2lhyDP"
            width="25%"
            height="50%"
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          />
          <iframe
            title="background-2"
            src="https://giphy.com/embed/h7uTwqEHysbd2lhyDP"
            width="25%"
            height="50%"
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          />
          <iframe
            title="background-3"
            src="https://giphy.com/embed/h7uTwqEHysbd2lhyDP"
            width="25%"
            height="50%"
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          />
          <iframe
            title="background-4"
            src="https://giphy.com/embed/h7uTwqEHysbd2lhyDP"
            width="25%"
            height="50%"
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          />
        </>
      )}
    </div>
  );
}
