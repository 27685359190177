import React, {useContext, useEffect, useState} from "react";
import styled, { keyframes } from "styled-components";
import styles from "./styles.module.scss";
import ParticipantDisplay from "../ParticipantDisplay";
import {GameMode, GameState, IMPOSTOR_ID, Player} from "../../models";
import {GameDataContext} from "../../gameData/GameDataContext";
import GameManager from "../../gameData/GameManager";

export enum BoardType {
  PLAYERS,
  DRAWINGS,
}

interface PlayersContainerProps {
  players?: Player[];
  type: BoardType;
}

export default function Board({ type, players }: PlayersContainerProps) {
  const { gameData } = useContext(GameDataContext);
  console.log(players);
  const renderPlayers = () => {
    const p = [];
    if(!players) return;
    for (let i = 0; i < players.length; i++) {
      const rotation = Math.random() * 20 - 10; // Between -10 and 10 deg.
      const translationX = Math.random() * 4 - 2;
      const translationY = Math.random() * 4 - 2;
      const style = {
        transform: `rotate(${rotation}deg) translate(${translationX}vh, ${translationY}vh)`,
      };
      const playerContainerAnimation = keyframes`
          0% {
              transform: scale(1);
          }
          50% {
              transform: scale(0.90);
          }
          100% {
              transform: scale(1);
          }
      `;
      const animationDelay = Math.random() * (2.5 - 1.5) + 1.5;
      const PlayerContainer = styled.div`
        animation: ${playerContainerAnimation} ${animationDelay}s infinite ease;
      `;

      let drawingStyle = styles.drawing;
      const winners = GameManager.getInstance().getWinners();
      const isImpostor = players[i].id === IMPOSTOR_ID;
      const isAmongWinners = winners.filter((winner) => winner.id === players[i].id).length > 0;
      if (gameData?.gameMode === GameMode.Impostor) {
        if (isImpostor) {
          drawingStyle = styles.drawingImpostor;
          if (gameData?.currentState === GameState.Results) {
            if (isAmongWinners) {
              drawingStyle = styles.drawingImpostorHighlightedRight;
            } else {
              drawingStyle = styles.drawingImpostorHighlighted;
            }
          }
        } else if (gameData?.currentState === GameState.Results && isAmongWinners) {
          drawingStyle = styles.drawingWrong;
        }
      }

      p.push(
        <PlayerContainer>
          {type === BoardType.PLAYERS ? (
            <ParticipantDisplay
              participant={players[i].name}
              avatar={players[i].avatar}
              style={style}
            />
          ) : (
            <div key={i}
                 className={drawingStyle}
                 style={style}>
              {isImpostor && gameData?.currentState === GameState.Results && <div className={styles.impostorBadge}></div>}
              <img src={players[i].draw}/>
            </div>
          )}
        </PlayerContainer>
      );
    }
    return p;
  };

  return <div className={styles.boardContainer}>{renderPlayers()}</div>;
}
