import React, { HTMLAttributes } from "react";
import styles from "./styles.module.scss";
import Glow from "../Glow";

import gameLogo from "../../assets/images/sketch-n-guess-party-logo.png";

export default function Logo({ className }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={className}>
      <Glow
        containerClassName={styles.logoContainer}
        glowClassName={styles.logoGlow}
      >
        <div className={styles.logoImg}>
          <img src={gameLogo} alt="img" />
        </div>
      </Glow>
    </div>
  );
}
