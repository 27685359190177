import React, { useContext } from "react";
import styles from "./styles.module.scss";
import commonStyles from "../PlayerBasePage/styles.module.scss";
import PillButton, { PillStyle } from "../../../components/buttons/PillButton";
import PlayerBasePage from "../PlayerBasePage";
import GameManager from "../../../gameData/GameManager";
import { GameDataContext } from "../../../gameData/GameDataContext";
import { Player } from "../../../models";
import Timer from "../../../components/Timer";

function PlayerVotingPage() {
  const { gameData } = useContext(GameDataContext);

  function buttonOnClick(player: Player): void {
    GameManager.getInstance().vote(player.id);
  }

  return (
    <PlayerBasePage>
      <div className={styles.mainContainer}>
        <div className={styles.contentContainer}>
          <div className={commonStyles.headerContainer}>
            <div className={commonStyles.title}>Vote the favourite</div>
            <div className={commonStyles.prompt}>{gameData?.currentPrompt?.toUpperCase()}</div>
            <div className={commonStyles.timerContainer}>
              <Timer/>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {gameData?.players.filter(p => p.drawSent).map((player, index) => (
              <PillButton
                onClick={() => {
                  buttonOnClick(player)
                }}
                pillStyle={PillStyle.PINK_OUTLINE}
                style={{height: "13vh", maxWidth: "90%" }}
                text={(index + 1).toString()}
              />
            ))}
          </div>
        </div>
      </div>
    </PlayerBasePage>
  );
}

export default PlayerVotingPage;
