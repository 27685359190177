import React, {useContext, useState} from "react";
import styles from "./styles.module.scss";
import commonStyles from "../PlayerBasePage/styles.module.scss";
import PlayerBasePage from "../PlayerBasePage";
import PainterComponent from "../../../components/canvas/PainterComponent";
import GameManager from "../../../gameData/GameManager";
import Timer, {ALMOST_UP_TIME, TIMEOUT} from "../../../components/Timer";
import {GameDataContext} from "../../../gameData/GameDataContext";

function PlayerDrawingPage() {
  const {gameData} = useContext(GameDataContext);
  const [isAlmostTimeout, setAlmostTimeout] = useState<boolean>(false);

  React.useEffect(() => {
    const start = Date.now();
    const timer = setInterval(() => {
      const now = Date.now();
      const count = TIMEOUT - ((now - start) / 1000);
      if (count <= ALMOST_UP_TIME) {
        setAlmostTimeout(true);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  function onDrawingCompleted(data: string): void {
    GameManager.getInstance().submitDrawing(data);
  }

  return (
    <PlayerBasePage>
      { isAlmostTimeout && <div className={styles.dimmer}/>}
      <div className={styles.mainContainer}>
        <div className={styles.contentContainer}>
          <div className={commonStyles.headerContainer}>
            <div className={commonStyles.title}>Draw!</div>
            <div className={commonStyles.prompt}>{gameData?.currentPrompt?.toUpperCase()}</div>
            <div className={commonStyles.timerContainer}>
              <Timer/>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <PainterComponent defaultStrokeColor="black" onDrawingCompleted={onDrawingCompleted}/>
          </div>
        </div>
      </div>
    </PlayerBasePage>
  );
}

export default PlayerDrawingPage;
