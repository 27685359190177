export enum SFX {
  BUTTON_CLICK = "https://m.media-amazon.com/images/G/01/kindle/tahoe/math-toy-grabber/sounds/sfx/single-tap.mp3",
}

export async function playSfx(sfx: SFX) {
  const audio = new Audio();
  audio.preload = "auto";
  audio.crossOrigin = "anonymous";
  audio.src = sfx;
  audio.load();
  await audio.play();
}
