export enum GameState {
  Idle = "idle",
  Waiting = "waiting",
  Drawing = "drawing",
  Uploading = "uploading",
  Voting = "voting",
  CalculatingImpostor = "calculating-impostor",
  Results = "results",
}

export type JoinResponse = {
  status: "OK" | "ERROR";
  message?: string;
};

export enum PlayingState {
  Playing = "playing",
  NotPlaying = "not-playing",
}

export type Vote = {
  playerId: string;
  votedPlayerId: string;
};

export type Player = {
  id: string;
  name: string;
  playingState: PlayingState;

  vote: Vote | undefined;
  voteCount: number;

  drawSent: boolean;
  draw: string | undefined;
  avatar: string;
};

export enum GameMode {
  Party = "party",
  Impostor = "impostor",
}

export type GameData = {
  players: Player[];
  currentState: GameState;
  currentPrompt?: string;
  gameMode: GameMode;
  isLocked: boolean;
};

export type Drawing = {
  playerId: string;
  image: string;
};

export const IMPOSTOR_ID = "{impostor}";
