import React, { CSSProperties, RefObject } from "react";
import { IconProperties } from "../../Icon";
import { SFX, playSfx } from "../../../audio";

import styles from "./styles.module.scss";

export enum PillStyle {
  GRAY,
  GRAY_OUTLINE_NO_SHADOW,
  GREEN,
  OUTLINE,
  PLAIN,
  JOURNAL,
  JOURNAL_OUTLINE,
  PINK_OUTLINE,
  WHITE_BLINK,
  TEAL,
}

export interface PillButtonProps {
  icon?: React.ReactElement<IconProperties>;
  iconRight?: boolean;
  text?: string;
  onClick: () => void;
  style?: CSSProperties | undefined;
  ariaLabel?: string;
  disabled?: boolean;
  pillRef?: RefObject<HTMLButtonElement>;
  pillStyle?: PillStyle;
}

function getPillStyle(pillStyle?: PillStyle) {
  switch (pillStyle) {
    case PillStyle.GRAY:
      return styles.pillButtonGray;
    case PillStyle.GRAY_OUTLINE_NO_SHADOW:
      return styles.pillButtonGrayOutlineNoShadow;
    case PillStyle.GREEN:
      return styles.pillButtonGreen;
    case PillStyle.OUTLINE:
      return styles.pillButtonOutline;
    case PillStyle.PLAIN:
      return styles.pillButtonPlain;
    case PillStyle.JOURNAL:
      return styles.journalButton;
    case PillStyle.JOURNAL_OUTLINE:
      return styles.journalButtonOutline;
    case PillStyle.PINK_OUTLINE:
      return styles.pinkButtonOutline;
    case PillStyle.WHITE_BLINK:
      return styles.whiteButtonBlink;
    case PillStyle.TEAL:
      return styles.tealButton;
    default:
      return styles.pillButton;
  }
}

export default function PillButton({
  icon,
  iconRight,
  text,
  onClick,
  style,
  ariaLabel,
  disabled,
  pillRef,
  pillStyle,
}: PillButtonProps) {
  const onClickWithSound = () => {
    playSfx(SFX.BUTTON_CLICK);
    onClick();
  };

  return (
    <button
      type="button"
      className={getPillStyle(pillStyle)}
      onClick={onClickWithSound}
      style={style}
      aria-label={ariaLabel}
      disabled={disabled}
      ref={pillRef}
    >
      {!iconRight && icon !== undefined && icon}
      <div className={styles.pillButtonText}>{text}</div>
      {iconRight && icon !== undefined && icon}
    </button>
  );
}
