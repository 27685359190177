import React, { PropsWithChildren } from "react";
import Background from "../../../components/Background";
import styles from "./styles.module.scss";

function PlayerBasePage({
  children,
}: PropsWithChildren<unknown>) {
  return (
    <div className={styles.pageContainer}>
      <Background showAnimation={false} />
      {children}
    </div>
  );
}

export default PlayerBasePage;
