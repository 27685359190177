import React, { useCallback, useContext, useEffect } from "react";
import styles from "./styles.module.scss";
import commonStyles from "../PlayerBasePage/styles.module.scss";
import PillButton, { PillStyle } from "../../../components/buttons/PillButton";
import PlayerBasePage from "../PlayerBasePage";
import GameManager from "../../../gameData/GameManager";

import gameLogo from "../../../assets/images/sketch-n-guess-party-logo.png";
import { GameDataContext } from "../../../gameData/GameDataContext";
import AppRoute from "../../../AppRoute";

function PlayerJoinPage() {
  const [name, setName] = React.useState("");
  const { isLocked, setPageInternal } = useContext(GameDataContext);

  const buttonOnClick = useCallback(() => {
    GameManager.getInstance().join(name, (response) => {
      if (response.status === "ERROR") {
        setPageInternal(AppRoute.IMPOSTOR_MAX_PLAYERS);
      }
    });
  }, [name, isLocked, setPageInternal]);

  useEffect(() => {
    if (isLocked && !GameManager.getInstance().userIsLogged()) {
      setPageInternal(AppRoute.IMPOSTOR_MAX_PLAYERS);
    }
  }, [isLocked, setPageInternal]);

  return (
    <PlayerBasePage>
      <div className={styles.mainContainer}>
        <div className={styles.mainContainerInner}>
        <div className={styles.logoImg}>
          <img src={gameLogo} alt="img" />
        </div>
        <div className={commonStyles.title}>Join the game!</div>
        <div className={styles.inputContainer}>
          <div className={styles.inputLabel}>Nickname</div>
          <input
            type="text"
            value={name}
            className={styles.nameInput}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className={styles.joinButtonContainer}>
          <PillButton
            onClick={buttonOnClick}
            pillStyle={PillStyle.PINK_OUTLINE}
            style={{ height: "11vh", border: "1vh solid #FFF" }}
            text="Join!"
          />
        </div>
      </div>
      </div>
    </PlayerBasePage>
  );
}

export default PlayerJoinPage;
