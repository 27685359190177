import React from "react";
import styles from "./styles.module.scss";
import QRCodeWrapper from "../QRCodeWrapper";
import Glow from "../Glow";
import { CLIENT_URL } from "../../environment";

function QRCodeBadge() {
  const endpoint = CLIENT_URL;

  return (
    <Glow
      containerClassName={styles.qrCodeBadgeContainer}
      glowClassName={styles.qrCodeGlow}
    >
      <div className={styles.badgeContainer}>
        <QRCodeWrapper qrValue={endpoint} />
      </div>
    </Glow>
  );
}

export default QRCodeBadge;
