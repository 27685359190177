import React, { useEffect, useState } from "react";
import * as Paper from "paper";
import styles from "./styles.module.scss";
import PillButton, { PillStyle } from "../../buttons/PillButton";
import {ALMOST_UP_TIME, TIMEOUT} from "../../Timer";

const instructionAudio = [
  "use_the_colors_to_draw_the_word_you_picked",
  "when_you_are_finished_tap_send",
];

interface PainterProperties {
  defaultStrokeColor: string;
  onDrawingCompleted: (data: string) => void;
}

function PainterComponent(props: PainterProperties) {
  const [isAlmostTimeout, setAlmostTimeout] = useState<boolean>(false);

  React.useEffect(() => {
    const start = Date.now();
    const timer = setInterval(() => {
      const now = Date.now();
      const count = TIMEOUT - ((now - start) / 1000);
      if (count <= ALMOST_UP_TIME) {
        setAlmostTimeout(true);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const [strokeColor, setStrokeColor] = useState<string>(
    props.defaultStrokeColor
  );
  const [paths, setPaths] = useState<paper.Path[]>([]);
  const [path, setPath] = useState<paper.Path | null>(null);

  const canvasRef = React.createRef<HTMLCanvasElement>();

  function getStrokeWidth() {
    return strokeColor === "white" ? 18 : 5;
  }

  function startPath(point: paper.Point) {
    const myPath = new Paper.Path();
    myPath.add(point);
    myPath.add(point);
    myPath.strokeColor = new Paper.Color(strokeColor);
    myPath.strokeWidth = getStrokeWidth();
    myPath.strokeCap = "round";
    setPath(myPath);
    setPaths((oldPaths) => {
      return [...oldPaths, myPath];
    });
  }

  function draw(): void {
    if (!Paper.view) {
      return;
    }
    Paper.view.onMouseDown = (event: {
      point: paper.Point;
      event: MouseEvent;
    }) => {
      startPath(event.point);
    };

    Paper.view.onMouseDrag = (event: {
      point: paper.Point;
      event: MouseEvent;
    }) => {
      if (path) {
        path.add(event.point);
      }
    };

    Paper.view.onMouseUp = (event: {
      point: paper.Point;
      event: MouseEvent;
    }) => {
      if (path) {
        setPath(null);
      }
    };

    (Paper.view as any).draw();
  }

  useEffect(
    function () {
      draw();
    },
    [strokeColor, paths, path]
  );

  function handleUndoClick(): void {
    setPaths((oldPaths) => {
      const path = oldPaths.pop();
      path?.remove();
      return oldPaths;
    });
  }

  function renderCanvas(): React.ReactNode {
    return (
      <canvas
        className={styles.canvas}
        ref={canvasRef}
        id="canvas"
        data-paper-hidpi="off"
      />
    );
  }

  function getCanvasData(): string {
    return canvasRef.current?.toDataURL("image/png", 1.0) ?? "";
  }

  useEffect(() => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    Paper.setup(canvas);
    const background = new Paper.Path.Rectangle({
      point: [0, 0],
      size: [canvas.width, canvas.height],
      strokeColor: 'white',
      fillColor: 'white',
      selected: false,
    });
    background.sendToBack();
    draw();
  }, []);

  function sendImage(): void {
    props.onDrawingCompleted(getCanvasData());
  }

  return (
    <div className={styles.gameControlsActive}>
      <div className={styles.drawWordContainer}>
        {renderCanvas()}
      </div>
      <div className={isAlmostTimeout ? styles.buttonContainer : ""}>
        <PillButton
          onClick={sendImage}
          pillStyle={PillStyle.PINK_OUTLINE}
          style={{ height: "11vh", border: "1vh solid #FFF" }}
          text="Send!"
        />
      </div>
    </div>
  );
}

export default PainterComponent;
