import React, { useEffect, useState } from "react";
import Board, { BoardType } from "../Board";
import styles from "./styles.module.scss";
import GameManager from "../../gameData/GameManager";
import { Player } from "../../models";

interface DrawingsBoardProps {
  title: string;
  subtitle?: string;
  players: Player[];
}

export default function DrawingsBoard({ title, subtitle, players }: DrawingsBoardProps) {
  if (players!= undefined) {
    return (
      <div className={styles.drawingsBoardContainer}>
        <div className={styles.drawingsBoardInstruction}>{title}</div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        <Board type={BoardType.DRAWINGS} players={players}/>
      </div>
    );
  }
  return null;
}
