import React, { CSSProperties } from "react";
import styles from "./styles.module.scss";
import Avatar, { AvatarType } from "../Avatar";

export interface ParticipantDisplayProps {
  participant: string;
  avatar: string;
  style?: CSSProperties;
}

export default function ParticipantDisplay({
  participant,
  avatar,
  style,
}: ParticipantDisplayProps) {
  const name = participant;

  return (
    <div className={styles.participantContainer} style={style}>
      <Avatar
        avatar={avatar}
        type={participant ? AvatarType.WHITE : AvatarType.BLUE}
      />
      <div
        className={participant ? styles.pill : styles.pillDark}
      >
        <p>{name}</p>
      </div>
    </div>
  );
}
