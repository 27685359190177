import React from "react";
import styles from "./styles.module.scss";
import PlayerBasePage from "../../player/PlayerBasePage";

import gameLogo from "../../../assets/images/sketch-n-guess-party-logo.png";

function NotFoundPage() {
  return (
    <PlayerBasePage>
      <div className={styles.mainContainer}>
        <div className={styles.logoImg}>
          <img src={gameLogo} alt="img" />
        </div>
        <div className={styles.title}>Waiting for mode selection</div>
      </div>
    </PlayerBasePage>
  );
}

export default NotFoundPage;
