import React from "react";
import Lottie from "lottie-react-web";
import styles from "./styles.module.scss";
import GameManager from "../../gameData/GameManager";

export const TIMEOUT = 30; // 30s
const BAR_WIDTH_MIN = 4;
const BAR_WIDTH_RANGE = 35 - BAR_WIDTH_MIN;
export const ALMOST_UP_TIME = 5;

enum TimerState {
  NORMAL,
  ALARM,
  FROZEN,
}

const BarStyle: Record<TimerState, string> = {
  [TimerState.NORMAL]: styles.normalBar,
  [TimerState.FROZEN]: styles.frozenBar,
  [TimerState.ALARM]: styles.almostUpBar,
};

function getState(count: number, state: TimerState): TimerState {
  if (state === TimerState.FROZEN && count <= ALMOST_UP_TIME) {
    return TimerState.FROZEN;
  }

  return count <= ALMOST_UP_TIME ? TimerState.ALARM : TimerState.NORMAL;
}

export default function Timer() {
  const [animation, setAnimation] = React.useState<unknown>(null);
  const [defaultAnimation, setDefaultAnimation] = React.useState<unknown>(null);
  const [frozenAnimation, setFrozenAnimation] = React.useState<unknown>(null);
  const [almostUpAnimation, setAlmostUpAnimation] =
    React.useState<unknown>(null);
  const [countdown, setCountdown] = React.useState<number>(0);
  const [counting, setCounting] = React.useState<boolean>();
  const [state, setState] = React.useState<TimerState>(TimerState.NORMAL);
  const [tickTimestamp, setTickTimestamp] = React.useState<number>(0);
  const [countdownTimestamp, setCountdownTimestamp] = React.useState<number>(0);

  React.useEffect(() => {
    fetch(
      "https://m.media-amazon.com/images/G/01/kindle/tahoe/math-toy-grabber/game/timer/clock-default.json"
    )
      .then((result) => result.json())
      .then((resultJson) => setDefaultAnimation(resultJson))
      .catch(console.error);
    fetch(
      "https://m.media-amazon.com/images/G/01/kindle/tahoe/math-toy-grabber/game/timer/clock-frozen.json"
    )
      .then((result) => result.json())
      .then((resultJson) => setFrozenAnimation(resultJson))
      .catch(console.error);
    fetch(
      "https://m.media-amazon.com/images/G/01/kindle/tahoe/math-toy-grabber/game/timer/clock-time-almost-up.json"
    )
      .then((result) => result.json())
      .then((resultJson) => setAlmostUpAnimation(resultJson))
      .catch(console.error);
  }, []);

  React.useEffect(() => {
    const newState = getState(countdown, state);
    if (newState !== state) {
      setState(newState);
    }
  }, [state, countdown]);

  React.useEffect(() => {
    if (state === TimerState.FROZEN) {
      if (frozenAnimation !== null && frozenAnimation !== animation) {
        setAnimation(frozenAnimation);
      }
    } else if (state === TimerState.ALARM) {
      if (almostUpAnimation !== null && almostUpAnimation !== animation) {
        setAnimation(almostUpAnimation);
      }
    } else if (defaultAnimation !== null && defaultAnimation !== animation) {
      setAnimation(defaultAnimation);
    }
  }, [defaultAnimation, frozenAnimation, almostUpAnimation, animation, state]);

  React.useEffect(() => {
    if (!counting) {
      setCountdown(TIMEOUT);
      setCounting(true);
      setTickTimestamp(Date.now());
      setCountdownTimestamp(TIMEOUT * 1000 + Date.now());
    }
  }, [countdown, counting, GameManager.getInstance().gameData?.currentState]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      const now = Date.now();
      if (counting) {
        if (countdown > 0) {
          const newCount = Math.ceil((countdownTimestamp - now) / 1000);
          setCountdown(newCount);
          if (newCount <= 0) {
            // onTimerExpired();
          } else if (newCount <= ALMOST_UP_TIME) {
            // playAudio("sfx", [Assets.SFX.CLOCK_TICK]);
          }
        } else {
          const diff = now - tickTimestamp;
          setCountdownTimestamp(countdownTimestamp + diff);
        }
      }
      setTickTimestamp(now);
    }, 1000);
    return () => clearInterval(timer);
  }, [
    countdown,
    counting,
    countdownTimestamp,
    tickTimestamp,
    setTickTimestamp,
    setCountdownTimestamp,
  ]);

  const barWidth = BAR_WIDTH_MIN + BAR_WIDTH_RANGE * (countdown / TIMEOUT);

  return (
    <div className={styles.container}>
      <img
        src="https://m.media-amazon.com/images/G/01/kindle/tahoe/math-toy-grabber/game/timer/timer-base.svg"
        alt=""
        className={styles.timerBarBackground}
        aria-label="Timer"
      />
      <div className={BarStyle[state]} style={{ width: `${barWidth}vh` }} />
      <div className={styles.clock} aria-label="timer">
        <Lottie
          options={{
            animationData: animation,
            loop: true,
            autoplay: true,
          }}
          ariaLabel="timer"
        />
      </div>
    </div>
  );
}
