import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import AppRoute from "./AppRoute";
import GameHomePage from "./pages/game/GameHomePage";
import PlayerJoinPage from "./pages/player/PlayerJoinPage";
import GameWaitingPage from "./pages/game/GameWaitingPage";
import PlayerWaitingPage from "./pages/player/PlayerWaitingPage";
import GameDrawingPage from "./pages/game/GameDrawingPage";
import GameVotingPage from "./pages/game/GameVotingPage";
import DebugPage from "./pages/common/DebugPage";
import DebugTvPage from "./pages/common/DebugTvPage";
import { GameDataContext } from "./gameData/GameDataContext";
import NotFoundPage from "./pages/common/NotFoundPage";
import PlayerDrawingPage from "./pages/player/PlayerDrawingPage";
import PlayerVotingPage from "./pages/player/PlayerVotingPage";
import GameResultsPage from "./pages/game/GameResultsPage";
import LoaderPage from "./pages/LoaderPage";

function App() {
  const { page } = useContext(GameDataContext);

  return (
    <Routes location={`/${page}`}>
      <Route path={`/${AppRoute.LOADER}`} element={<LoaderPage />} />
      {/* debug */}
      <Route path={`/${AppRoute.DEBUG}`} element={<DebugPage />} />
      <Route path={`/${AppRoute.DEBUGFIRETV}`} element={<DebugTvPage />} />
      {/* tv */}
      <Route path={`/${AppRoute.HOME}`} element={<GameHomePage />} />
      <Route path={`/${AppRoute.WAITING}`} element={<GameWaitingPage />} />
      <Route path={`/${AppRoute.DRAWING_GAME}`} element={<GameDrawingPage />} />
      <Route
        path={`/${AppRoute.DRAWINGS_BOARD_GAME}`}
        element={<GameVotingPage />}
      />
      <Route path={`/${AppRoute.RESULTS_GAME}`} element={<GameResultsPage />} />
      {/* player */}
      <Route path={`/${AppRoute.NotFound}`} element={<NotFoundPage />} />
      <Route path={`/${AppRoute.JOIN}`} element={<PlayerJoinPage />} />
      <Route path={`/${AppRoute.DRAWING_PLAYER}`} element={<PlayerDrawingPage />} />
      <Route path={`/${AppRoute.VOTING_PLAYER}`} element={<PlayerVotingPage />} />
      <Route
        path={`/${AppRoute.IMPOSTOR_MAX_PLAYERS}`}
        element={<PlayerWaitingPage message={"Maximum players reached for impostor game mode"} />}
      />
      <Route
        path={`/${AppRoute.WAITING_PLAYER}`}
        element={<PlayerWaitingPage message={"Waiting for other players to join..."} />}
      />
      <Route
        path={`/${AppRoute.WAITING_DRAWINGS_PLAYER}`}
        element={<PlayerWaitingPage message={"Waiting for other players to send their drawings..."} />}
      />
      <Route
        path={`/${AppRoute.WAITING_VOTES_PLAYER}`}
        element={<PlayerWaitingPage message={"Waiting for other players to vote..."} />}
      />
      <Route
        path={`/${AppRoute.WAITING_NEXT_ROUND_PLAYER}`}
        element={<PlayerWaitingPage message={"Waiting for host to start the next round!"} />}
      />
    </Routes>
  );
}

export default App;
