import React, { useContext, useEffect, useState } from "react";
import GameBasePage from "../GameBasePage";
import styles from "./styles.module.scss";
import Glow from "../../../components/Glow";
import Timer from "../../../components/Timer";
import { GameDataContext } from "../../../gameData/GameDataContext";

import gameLogo from "../../../assets/images/sketch-n-guess-party-logo.png";

function DrawingPrompt() {
  const { gameData } = useContext(GameDataContext);
  const [prompt, setPrompt] = useState<string | undefined>("");

  useEffect(() => {
    setPrompt(gameData?.currentPrompt);
  }, [gameData?.currentPrompt]);

  return (
    <div>
      <Glow
        containerClassName={styles.drawingPromptContainer}
        glowClassName={styles.drawingPromptGlow}
      >
        <div className={styles.drawingPromptContainerInner}>
          <div className={styles.drawingPromptInstruction}>Draw!</div>
          <div className={styles.drawingPrompt}>{prompt?.toUpperCase()}</div>
        </div>
      </Glow>
    </div>
  );
}

function DrawingGamePage() {

  return (
    <GameBasePage>
      <div className={styles.mainContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.logoImg}>
            <img src={gameLogo} alt="img" />
          </div>
          <div className={styles.instructionsContainer}>
            <DrawingPrompt />
          </div>
          <div className={styles.timerContainer}>
            <Timer />
          </div>
        </div>
        </div>
    </GameBasePage>
  );
}

export default DrawingGamePage;
