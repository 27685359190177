import React, { useEffect, useMemo, useState } from "react";
import { ReactNode } from "react";
import { GameData, GameMode, GameState } from "../models";
import GameManager from "./GameManager";
import AppRoute from "../AppRoute";
import { useSearchParams } from "react-router-dom";

export interface GameDataState {
    gameData: GameData | undefined,
    page: string | null,
    isLocked: boolean,
    setPageInternal: (page: string) => void;
}
const defaultGameData: GameData = {
    players: [],
    currentState: GameState.Waiting,
    gameMode: GameMode.Party,
    isLocked: false,
}
export const GameDataContext = React.createContext<GameDataState>({
  gameData: defaultGameData,
  page: null,
  isLocked: false,
  setPageInternal: (_page) => undefined,
});

export interface GameDataProviderProps {
    children: ReactNode,
};

const checkIfLocked = (gameManager: GameManager, gameData?: GameData) => {
  return (gameData?.isLocked ?? false);
};

export function GameDataProvider(props: GameDataProviderProps) {
    const [page, setPage] = useState<string>('');
    const [gameData, setGameData] = useState<GameData>();
    const [searchParams] = useSearchParams();

    const handleUnload = () => {
        GameManager.getInstance().disconnect();
    };

  const isLocked = checkIfLocked(GameManager.getInstance(), gameData);
    
    useMemo(() => {
        setPage(AppRoute.LOADER);

        window.addEventListener('beforeunload', handleUnload);
        const isFireTV = searchParams.get("fire");
        const isDebug = searchParams.get("debug");

        // Generate ID for player
        GameManager.getInstance().onStateChanged((data: GameData) => {
            setGameData(data);
            const isLockedState = checkIfLocked(GameManager.getInstance(), gameData);

            // Debug
            if (isDebug) {
                setPage(AppRoute.DEBUGFIRETV);
                return;
            }
            // Viewer (FireTV)
            if (isFireTV) {
                if (GameManager.getInstance().gameData?.currentState === GameState.Idle) {
                    setPage(AppRoute.HOME);
                    return;
                }
                if (GameManager.getInstance().gameData?.currentState === GameState.Waiting) {
                    setPage(AppRoute.WAITING);
                    return;
                }
                if (GameManager.getInstance().gameData?.currentState === GameState.Drawing) {
                    setPage(AppRoute.DRAWING_GAME);
                    return;
                }

                if (GameManager.getInstance().gameData?.currentState === GameState.Voting
                    || GameManager.getInstance().gameData?.currentState === GameState.Uploading) {
                    setPage(AppRoute.DRAWINGS_BOARD_GAME);
                    return;
                }
                if (GameManager.getInstance().gameData?.currentState === GameState.Results) {
                    setPage(AppRoute.RESULTS_GAME);
                    return;
                }
                return;
            }

            // App (Player)
            if (isLockedState) {
                if (!GameManager.getInstance().userIsLogged()) {
                    setPage(AppRoute.IMPOSTOR_MAX_PLAYERS);
                    return;
                } else {
                    setPage(AppRoute.WAITING_PLAYER);
                    return;
                }
            }

            if (GameManager.getInstance().gameData?.currentState === GameState.Idle) {
                setPage(AppRoute.NotFound);
                return;
            }

            if (!GameManager.getInstance().userIsLogged()) {
                setPage(AppRoute.JOIN);
                return;
            }

            if (GameManager.getInstance().gameData?.currentState === GameState.Waiting) {
                setPage(AppRoute.WAITING_PLAYER);
                return;
            }

            if (GameManager.getInstance().gameData?.currentState === GameState.Drawing
                || GameManager.getInstance().gameData?.currentState === GameState.Uploading
                || GameManager.getInstance().gameData?.currentState === GameState.CalculatingImpostor) {
                if (!GameManager.getInstance().userDrew()) {
                    setPage(AppRoute.DRAWING_PLAYER);
                } else {
                    setPage(AppRoute.WAITING_DRAWINGS_PLAYER);
                }
                return;
            }

            if (GameManager.getInstance().gameData?.currentState === GameState.Voting) {
                if (!GameManager.getInstance().userVoted()) {
                    setPage(AppRoute.VOTING_PLAYER);
                } else {
                    setPage(AppRoute.WAITING_VOTES_PLAYER);
                }
                return;
            }

            if (GameManager.getInstance().gameData?.currentState === GameState.Results) {
                setPage(AppRoute.WAITING_NEXT_ROUND_PLAYER);
                return;
            }
        });
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        }
    }, [setGameData, setPage])

    return (
        <GameDataContext.Provider value={{ gameData: gameData, page: page, isLocked, setPageInternal: (page) => setPage(page) }}>
            {props.children}
        </GameDataContext.Provider>
    )
}